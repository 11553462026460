@import url("https://fonts.googleapis.com/css?family=Open+Sans");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.accordionContainer {
  position: relative;
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.accordionContainer ul {
  padding-left: 0;
  position: relative;
}

/* LIST */
ul {
  list-style: none;
  height: 100%;
}

.behindAccordionContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.backdropTitle {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
}

.backdropTitle h1 {
  font-size: 6vw;
  font-weight: 10000;
  text-transform: uppercase;
  margin-bottom: 0.2em;
}

.move {
  color: rgba(0, 0, 0, 0.774);
}

.theImpossible {
  color: rgba(255, 255, 255, 0.774);
}

/* FORM */
input {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

label {
  float: left;
  height: 85vh;
  width: 80px;
  overflow: hidden;
  font-size: 14px;
  line-height: 50px;
  -webkit-transition: background 300ms ease;
  transition: background 300ms ease;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari/Chrome/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.title {
  font-size: 1.5em;
  color: #000;
  white-space: nowrap;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-weight: 600;
}

#rad1.title {
  position: relative;
  top: 53%;
}

#rad2.title {
  position: relative;
  top: 54%;
}

#rad3.title {
  position: relative;
  top: 49%;
}

#rad4.title {
  position: relative;
  top: 53%;
}

li:nth-child(1) > input + label {
  background: #999898d3;
}

li:nth-child(2) > input + label {
  background: #777676d8;
}

li:nth-child(3) > input + label {
  background: #5f5e5eda;
}

li:nth-child(4) > input + label {
  background: #494949dc;
}

label:hover,
li:nth-child(1) > input + label:hover {
  background: #ec0e0ec2;
  color: #fff;
  cursor: pointer;
}

label:hover,
li:nth-child(2) > input + label:hover {
  background: #ec0e0ec2;
  color: #fff;
  cursor: pointer;
}

label:hover,
li:nth-child(3) > input + label:hover {
  background: #ec0e0ec2;
  color: #fff;
  cursor: pointer;
}

label:hover,
li:nth-child(4) > input + label:hover {
  background: #ec0e0ec2;
  color: #fff;
  cursor: pointer;
}

input[type="radio"]:checked ~ label {
  background: #ec0e0ec2;
  color: #fff;
  cursor: default !important;
}

/* SLIDES */
.accslide {
  z-index: 2;
  display: block;
  height: 85vh;
  width: 0px;
  float: left;
  overflow-x: hidden;
  font-size: 12px;
  line-height: 1.5;
  -webkit-transition: all 700ms ease;
  transition: all 100ms ease;
  background-repeat: no-repeat;
}

.accslide * {
  padding-left: 10px;
  padding-bottom: 0px;
}

.accslide img {
  margin-top: 10px;
}

input[type="radio"]:checked ~ label > * {
  display: none;
}

.writingBoxContainer {
  width: 100%;
  min-height: 40vh; /* Use min-height instead of fixed height */
  max-height: 70vh; /* You can adjust this based on your design */
  display: flex;
  background-color: black;
  flex-direction: column;
  align-items: left; /* This property is invalid, use align-items: flex-start; */
  overflow: auto; /* Allow content to overflow and trigger scroll if needed */
}

.BackDropContent {
  flex: 1;
  display: block;
  position: relative;
  color: white;
  text-align: left;
  padding-left: 30vw;
  padding-right: 35vw;
  top: 20vh;
}

.BackDropSmallSentence {
  pointer-events: all;
}

/* ACCORDION MACHINE MOVING*/
.content4 {
  flex: 1;
  display: block;
  position: relative;
  text-align: left;
  padding-left: 35vw;
  padding-right: 0vw;
  top: 16vh;
}

#title {
  color: #1a1919;
  font-size: 2em;
  font-weight: bold;
}

#info4 {
  color: #1a1919;
  font-size: 4.5em;
}

#learn {
  font-size: 1.4em;
  font-weight: bold;
  color: rgb(248, 2, 2);
}

.panel4-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  padding-top: 15vh;
  padding-left: 5vw;
  padding-right: 0vw;
  border-color: white;
  border-radius: 50%;
}

/* ACCORDION WAREHOUSING*/
.content3 {
  flex: 1;
  display: block;
  position: relative;
  text-align: left;
  padding-left: 15vw;
  top: 20vh;
}

#title {
  color: #1a1919;
  font-size: 2em;
  font-weight: bold;
}

#info3 {
  color: #1a1919;
  font-size: 4.5em;
  padding-right: 20vw;
}

#learn {
  font-size: 1.4em;
  font-weight: bold;
  color: rgb(248, 2, 2);
}

.panel3-image {
  position: absolute;
  right: 50px;
  width: 25%;
  padding-top: 15vh;
  padding-left: 5vw;
  padding-right: 0vw;
  border-color: white;
  border-radius: 50%;
}

/* ACCORDION TRUCK SERVICES*/
.content2 {
  flex: 1;
  display: block;
  position: relative;
  text-align: left;
  padding-left: 35vw;
  padding-right: 0vw;
  top: 20vh;
}

#title {
  color: #1a1919;
  font-size: 2em;
  font-weight: bold;
}

#info2 {
  color: #1a1919;
  font-size: 4em;
}

#learn {
  font-size: 1.4em;
  font-weight: bold;
  color: rgb(248, 2, 2);
}

.panel2-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  padding-top: 15vh;
  padding-left: 5vw;
  padding-right: 0vw;
  border-color: white;
  border-radius: 50%;
}

/* ACCORDION MILLWRIGHT  SERVICES*/
.content1 {
  flex: 1;
  display: block;
  position: relative;
  text-align: left;
  padding-left: 15vw;
  top: 20vh;
}

#title {
  color: #1a1919;
  font-size: 2em;
  font-weight: bold;
}

#info1 {
  color: #1a1919;
  font-size: 3.5em;
  padding-right: 20vw;
}

#learn {
  font-size: 1.4em;
  font-weight: bold;
  color: rgb(248, 2, 2);
  text-decoration: rgb(248, 2, 2) underline;
}

#learn:hover {
  color: rgb(237, 92, 92);
}

.panel1-image {
  position: absolute;
  right: 50px;
  width: 30%;
  padding-top: 15vh;
  padding-left: 5vw;
  padding-right: 0vw;
  border-color: white;
  border-radius: 50%;
}

@media (max-width: 1180px) {
  .BackDropContent {
    padding-left: 35vw;
    padding-right: 25vw;
  }
}

.BackDropLargeSentence {
  font-size: 3em;
  font-weight: bold;
}

.BackDropSmallSentence {
  font-size: 1em;
  color: white;
}
.BackDropSmallSentence a {
  text-decoration: none !important; /* Remove the underline from links within .BackDropSmallSentence */
}
.BackDropSmallSentence:hover {
  color: rgb(212, 210, 210);
}

li:nth-child(1):nth-last-child(2) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(2):nth-last-child(1) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(1):nth-last-child(3) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 160px);
}
li:nth-child(2):nth-last-child(2) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 160px);
}
li:nth-child(3):nth-last-child(1) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 160px);
}
li:nth-child(1):nth-last-child(4) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(2):nth-last-child(3) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(3):nth-last-child(2) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(4):nth-last-child(1) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(1):nth-last-child(5) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 80px);
}
li:nth-child(2):nth-last-child(4) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 80px);
}
li:nth-child(3):nth-last-child(3) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 80px);
}
li:nth-child(4):nth-last-child(2) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 80px);
}
li:nth-child(5):nth-last-child(1) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 80px);
}
li:nth-child(1):nth-last-child(6) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 160px);
}
li:nth-child(2):nth-last-child(5) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 160px);
}
li:nth-child(3):nth-last-child(4) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 160px);
}
li:nth-child(4):nth-last-child(3) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 160px);
}
li:nth-child(5):nth-last-child(2) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 160px);
}
li:nth-child(6):nth-last-child(1) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 160px);
}
li:nth-child(1):nth-last-child(7) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 240px);
}
li:nth-child(2):nth-last-child(6) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 240px);
}
li:nth-child(3):nth-last-child(5) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 240px);
}
li:nth-child(4):nth-last-child(4) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 240px);
}
li:nth-child(5):nth-last-child(3) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 240px);
}
li:nth-child(6):nth-last-child(2) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 240px);
}
li:nth-child(7):nth-last-child(1) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 240px);
}
li:nth-child(1):nth-last-child(8) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(2):nth-last-child(7) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(3):nth-last-child(6) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(4):nth-last-child(5) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(5):nth-last-child(4) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(6):nth-last-child(3) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(7):nth-last-child(2) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}
li:nth-child(8):nth-last-child(1) input[type="radio"]:checked ~ .accslide {
  width: calc(100% - 320px);
}

.accordionMobileIntro {
  display: none;
}

@media (max-width: 992px) {
  .accordionContainer {
    height: 100%;
  }
  .accordionDesktop {
    display: none;
  }

  .accordionMobileIntro {
    display: block;
    height: 70vh;
  }

  .BackDropContent {
    display: block;
    color: white;
    text-align: left;
    padding-left: 1vw;
    padding-right: 1vw;
    top: 5vh;
  }

  .backdropTitle {
    position: absolute;
    top: 53vh; /* Stick to the top of the container */
    right: 0; /* Stick to the left of the container */
    text-align: right;
  }

  .move {
    color: rgb(255, 255, 255);
  }

  .theImpossible {
    color: rgba(255, 255, 255, 0.774);
  }

  .backdropTitle h1 {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.2em;
  }

  .accordionMobile1 {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    font-weight: bold;
  }

  .active,
  .accordionMobile1:hover {
    background-color: #ccc;
  }

  .accordionMobile1:after {
    content: "\02795"; /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
  }

  .accordionMobile1.active:after {
    content: "\2796"; /* Unicode character for "minus" sign (-) */
  }

  .panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-image: url("/public/images/iconBoxBackground.png");
  }

  .panelMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }

  .panel-image-mobile {
    display: block;
    margin: 0 auto;
    width: 50%;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.959);
  }

  #learnMobile {
    font-size: 0.8em;
    font-weight: bold;
    color: rgb(248, 2, 2);
    text-decoration: rgb(248, 2, 2) underline;
  }

  
  #learnMobile:hover {
    color: rgb(237, 92, 92);
  }

  .infoMobile {
    color: #1a1919;
    font-size: 1.3em;
    padding-right: 20vw;
    padding-left: 5vw;
    font-weight: bold;
    text-align: left;
  }
}
