

.heroContainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.HeroVideoIndex {
  width: 100%;
  height: 100vh; /* Ensure it covers the full height of the viewport */
  object-fit: cover; /* Cover the container without losing aspect ratio */
}

.ImageContainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.HeroImageIndex {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.BlackOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
}

.LogoContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; /* Center align the content horizontally */
}

.LogoImage {
  width: 40vw;
  animation: fadeInScaleUp 1.5s ease-out forwards;
}

.LogoButton {
  display: none;
}

@keyframes fadeInScaleUp {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}


@media (max-width: 992px) {
  .LogoImage {
    width: 70vw;
  }

  .LogoButton {
    display: block;
    margin-top: 10px;
    
  }

  .heroContainer {
    position: relative;
    width: 100%;
    min-height: 80vh;
    margin: 0 auto;
  }

  .HeroImageIndex {
    width: 100%;
    object-fit: cover;
  }


}
