.headerMain {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(0, 0, 0);
  padding: 20px;
  color: #fff;
  z-index: 1000;
}



