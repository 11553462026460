/* @font-face {
  font-family: "Playfair Display";
  src: url('C:\Users\bardi\Documents\Alpha1\Alpha1Services\public\fonts\HomeAboutUs\OpenSans-Italic-VariableFont_wdth,wght.ttf');
} */

.homeAboutUsContainer {
  width: 100%;
  min-height: 200vh;
  background-image: url("/public/images/homeAboutUsBG.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 3vh;
}

.homeAUtitle {
  flex: 1;
  display: block;
  text-align: left;
  position: relative;
  padding-top: 20vh;
  padding-left: 20vw;
  padding-right: 20vw;
  font-size: 3rem;
  font-weight: bold;
}

.homeAUtitleGrid {
  display: grid;
  grid-template-columns: auto auto;
}

.largeSentenceAU {
  font-size: 1em;
  font-weight: bold;
  color: rgb(20, 20, 20);
}

.AULogoImage {
  width: 60%;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

#learnAU {
  padding-top: 10px;
  font-size: 0.4em !important;
  font-weight: bold !important;
  color: rgb(248, 2, 2);
  text-decoration: rgb(248, 2, 2) underline;
}

.homeAUContentGrid {
  display: grid;
  grid-template-columns: auto 5px auto;
  grid-template-rows: auto auto auto;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 15vh;
}

.centerBar {
  grid-column: 2;
  grid-row: 1 / span 3;
  background-color: rgb(14, 13, 13);
}

.AUimgLeft {
  width: 60%;
  box-shadow: 0px 20px 20px rgb(0, 0, 0);
  float: right;
}

.AUimgRight {
  width: 60%;
  box-shadow: 0px 20px 20px rgb(0, 0, 0);
  float: Left;
}

.AUtext {
  padding: 8vh;
  padding-top: 12vh;
  font-size: 1.5vw;
  color: rgb(36, 23, 23);
  text-align: left;
  /* font-family: "Playfair Display", serif; */
    line-height: 1.5; /* You can adjust this value */

}

.AUimg1 {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  object-fit: cover;
}

.AUtext1 {
  grid-column: 3;
  grid-row: 1;
  width: 100%;
  object-fit: cover;
}

.AUimg2 {
  grid-column: 3;
  grid-row: 2;
  width: 100%;
  object-fit: cover;
}

.AUtext2 {
  grid-column: 1;
  grid-row: 2;
  width: 100%;
  object-fit: cover;
}

.AUimg3 {
  grid-column: 1;
  grid-row: 3;
  width: 100%;
  object-fit: cover;
}

.AUtext3 {
  grid-column: 3;
  grid-row: 3;
  width: 100%;
  object-fit: cover;
}

#AUimgLeft {
  position: absolute;
  left: -100px;
  width: 100px;
  height: 100px;
  background: blue;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
}

@-webkit-keyframes AUimgLeft {
  100% { left: 0; }
}

@keyframes AUimgLeft {
  100% { left: 0; }
}

@media (max-width: 800px) {
  .homeAUtitleGrid {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: none;
  }

  .AULogoImage {
    grid-row: 1;
    display: block;
    margin: 0 auto;
  }

  .AULogoImage {
    width: 20%;
  }

}

@media (max-width: 992px) {

  .homeAUtitle {
    text-align: center;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 5vh;
    font-size: 2rem;
  }

  .homeAUContentGrid {
    grid-template-columns: 5px auto;
    grid-template-rows: auto auto auto auto auto auto;

    justify-content: center; /* Centering horizontally */
    align-items: center; /* Centering vertically */
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 5vh;
  }

  .centerBar {
    grid-column: 1;
    grid-row: 1 / span 6;
  }

  .AUimgLeft,
  .AUimgRight {
    float: none; /* Reset the float property */
    margin: 0 auto; /* Center horizontally using margin */
    width: 80%;
    display: block; /* Make sure the image behaves as a block element */
    margin: 0 auto; /* Center the image horizontally */
  }

  .AUtext {
    padding: 1vh;
    padding-top: 5vh;
    padding-left: 4vh;
    font-size: 30px;
    text-align: left;
  }
  
  .AUimg1 {
    grid-column: 2;
    grid-row: 1;
    width: 100%;
    object-fit: cover;
  }
  
  .AUtext1 {
    grid-column: 2;
    grid-row: 2;
    width: 100%;
    object-fit: cover;
  }
  
  .AUimg2 {
    grid-column: 2;
    grid-row: 3;
    width: 100%;
    object-fit: cover;
  }
  
  .AUtext2 {
    grid-column: 2;
    grid-row: 4;
    width: 100%;
    object-fit: cover;
  }
  
  .AUimg3 {
    grid-column: 2;
    grid-row: 5;
    width: 100%;
    object-fit: cover;
  }
  
  .AUtext3 {
    grid-column: 2;
    grid-row: 6;
    width: 100%;
    object-fit: cover;
  }
}