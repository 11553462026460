.sticky-quote-button {
    display: none;
    background-color: rgb(201, 22, 22);
    color: #fff;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(243, 0, 0, 0.5); /* optional shadow for a lifted effect */
    white-space: nowrap;

    position: fixed;
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    z-index: 1000; /* Ensure the button stays above other content */
  }
  
  .quote-button:hover {
    background-color: #610505;
  }
  
  @media (max-width: 992px) {
    .sticky-quote-button {
      border-radius: 25px; /* Reduced border radius for a smaller button */
      padding: 6px 15px; /* Adjusted padding for a smaller button */
      font-size: 14px; /* Reduced font size for a smaller button */
    }
  }
  