/* styles.css */
.fadeInLeft {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1s, transform 1s;
  }
  
  .fadeOutLeft {
    opacity: 0;
    transform: translateX(-20%); /* slide from the left */
    transition: opacity 1s, transform 1s;
  }
  