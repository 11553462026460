.FRContainer {
    position: relative; 
    width: 100%;
    min-height: 40vh;
    display: flex;
    background-color: rgb(255, 66, 42);
    overflow: hidden; 
    cursor: pointer;
}

.Triangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.5); 
    clip-path: polygon(100% 100%,0 0,0 100%,100% 100%);
    z-index: 1; 
    transition: clip-path 0.3s ease;  /* transition on clip-path */
}

.FRContainer:hover .Triangle {
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
}

.FRContent {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 10vh;
    z-index: 2;
    text-align: center;
}

.FRTitle {
    font-size: 3vw;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 86px;
    text-transform: none;
    color: #ffffff;
}

/* Button Styles */
.FRSquareButton {
    display: inline-block;
    border: 4px solid black;
    background-color: black;
    box-shadow: -20px 20px 20px rgba(0, 0, 0, 0.712);
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    margin-top: 10px; 
    border-radius: 50px;
}

.FRSquareButton:hover {
    background-color: rgb(214, 55, 55);
    color: white;
}

@media (max-width: 992px) {
    .FRTitle {
        font-size: 5vw;
    }

    .FRContent {
        top: 7vh;
    }
}
    

