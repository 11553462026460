.ServicesWBContainer {
    width: 100%;
    min-height: 20vh; /* Use min-height instead of fixed height */
    display: flex;
    background-color: black;
    flex-direction: column;
    align-items: left; /* This property is invalid, use align-items: flex-start; */
}

.ServicesWBContent {
    flex: 1;
    display: block;
    padding: 6vw;
    color: white;
    text-align: left;
    position: relative;
    padding-left: 10vw;
}

.ServicesLargeSentence {
    font-size: 3rem;
}



@media (max-width: 800px) {
    .ServicesWBContent {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}
