@import url("https://fonts.googleapis.com/css?family=Open+Sans");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
}
