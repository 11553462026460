/* styles.css */
.fadeInRight {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 1s, transform 1s;
}
  
.fadeOutRight {
    opacity: 0;
    transform: translateX(20%); /* slide from the right */
    transition: opacity 1s, transform 1s;
}
