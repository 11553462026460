header {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  padding: 20px;
  color: #fff;
  transition: top 0.3s ease;
  z-index: 1000;
}

.sticky {
  position: fixed;
  top: 0;
  bottom: auto;
  background: #000;
}

.HeaderLogoImage {
  max-width: 45%;
  padding-right: 40px;
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li {
  margin-right: 10px;
}

/* Bootstrap overrides */
.navbar {
  background: transparent;
}

header .navbar-toggler {
  background-color: rgba(255, 255, 255, 0.534);
}

.navbar-toggler-icon {
  background-color: transparent;
}

.navbar-nav {
  flex-direction: row;
}

.nav-link {
  color: #fff !important;
  margin-right: 10px;
}

.nav-link:hover {
  color: #da0b0b !important;
}

.nav-item {
  margin-right: 10px;
}

.offcanvas-body {
  background-color: #000;
}

.offcanvas-header {
  background-color: #c42626;
  color: #fff;
}


@media (max-width: 992px) {
  .navbar-collapse {
    display: none;
  }

  .nav-link {
    font-size: 1.5em !important;
  }

  .RequestButtonMobile {
    display: none;
  }
}
