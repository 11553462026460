.AUWritingBox2Container {
    width: 100%;
    min-height: 30vh;
    display: flex;
    background-image: url("/public/images/AUImages/WorkersSunset2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    color: white;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    padding-top: 5vh;
    padding-bottom: 5vh;
    font-size: 20px;
    line-height: 36px;
}

.AUWritingBox2Container p {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

@media (max-width: 992px) {
    .AUWritingBox2Container p{
        width: 90%;
    }

}