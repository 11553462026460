
  .footer {
    background-color: #000000;
    color: white;
    padding: 40px 0;
    border-top: white 5px solid;
  }

  .FooterLogoImage {
    position: absolute;
    margin-left: 60vw;
    opacity: 0.1;
    width: 10%;
  }
  
  .container {
    width: 90%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 15vw;
    padding-right: 15vw;
  }
  
  .col-6 {
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  .logo-image {
    max-width: 60%;
    height: auto;
    position: relative;
  }

  .footer-content1 li {
    padding-left: 4vw;
  }
  

  .footer-content2 {
    padding-top: 3vh;
  }
  
  .footer-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-menu li {
    margin-bottom: 10px;
  }
  
  .contact-info {
    margin: 0;
    font-size: 14px;
  }
  
  .copy-right {
    font-size: 14px;
  }

  
  .social-icons {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  
  .social-icon {
    margin-right: 10px;
    font-size: 24px;
    color: white;
    text-decoration: none;
  }
  
  /* Media query for mobile view */
  @media (max-width: 992px) {
    .row {
      flex-direction: column;
    }
  
    .col-6 {
      width: auto;
      margin-right: 0;
    }
  
    .social-icons {
      justify-content: center;
      margin-top: 20px;
    }
  
    .text-end {
      text-align: center;
    }

      
  .logo-image {
    max-width: 100%;
    height: auto;
    position: relative;
  }

    .FooterLogoImage {
        position: absolute;
        margin-left: 40vw;
        opacity: 0.1;
        width: 40%;
      }

    .contact-info {
      width: 60vw;
    }
  }
  