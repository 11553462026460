.writingBoxContainer {
    width: 100%;
    min-height: 50vh; /* Use min-height instead of fixed height */
    display: flex;
    background-color: black;
    flex-direction: column;
    align-items: left; /* This property is invalid, use align-items: flex-start; */
}

.wrightingBoxContent {
    flex: 1;
    display: block;
    padding: 6vw;
    color: white;
    text-align: left;
    position: relative;
    padding-left: 10vw;
    padding-right: 40vw;
}

.largeSentence {
    font-size: 3rem;
    font-weight: bold;
}

.smallSentence {
    font-size: 1.5em;

}

@media (max-width: 800px) {
    .wrightingBoxContent {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}
