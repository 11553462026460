.AUCoreValuesContainer {
  width: 100%;
  min-height: 50vh;
  background-image: url("/public/images/homeAboutUsBG.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-bottom: 5vh;
}

.CoreValuesTitle {
  text-align: center;
  padding-top: 10vh;
  font-size: 2rem;
  font-weight: bold;
}

.CoreValuesTitleGrid {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.AUCoreValuesTitle {
  font-size: 1.5em;
  font-weight: bold;
  color: rgb(20, 20, 20);
  position: relative;
  margin-right: auto;
}

.CoreValuesLogoImage {
  max-width: 100px;
  position: relative;
  margin-left: auto;
}

.theValuesGrid1,
.theValuesGrid2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
  padding-top: 5vh;
}

.Excellence,
.Integrity,
.Reliability {
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
}

.Collaboration,
.Safety {
  text-align: center;
  padding-left: 200px;
  padding-right: 200px;
}

@media (max-width: 1200px) {
  .Excellence,
.Integrity,
.Reliability {
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}

.Collaboration,
.Safety {
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
}
}

@media (max-width: 992px) {
  .CoreValuesTitle {
    padding-top: 5vh;
    font-size: 1.5rem;
  }

  .theValuesGrid1,
  .theValuesGrid2 {
    padding-top: 2.5vh;
    gap: 10px;
  }


  .Excellence,
  .Integrity,
  .Reliability {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .Collaboration,
  .Safety {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
}
