.quote-button {
  background-color: rgb(201, 22, 22);
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(243, 0, 0, 0.5); /* optional shadow for a lifted effect */
  white-space: nowrap;
}

.quote-button:hover {
  background-color: #610505;
}

@media (max-width: 992px) {
  .quote-button {
    border-radius: 25px; /* Reduced border radius for a smaller button */
    padding: 6px 15px; /* Adjusted padding for a smaller button */
    font-size: 14px; /* Reduced font size for a smaller button */
  }
}
