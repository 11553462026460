.AUIntroContainer {
    width: 100%;
    min-height: 50vh;
    display: flex;
    background-color: black;
    color: white;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    padding-top: 5vh;
    padding-bottom: 5vh;
    font-size: 20px;
    line-height: 36px;
}

.AUIntroContainer p {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.LogoWithOutA {
    width: 20vw;
    padding-bottom: 5vh;

}

@media (max-width: 992px) {
    .AUIntroContainer p{
        width: 90%;
    }
    .LogoWithOutA {
        width: 30vw;
    
    }
}
    


