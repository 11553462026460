.ServicesDropDownsContainer {
  display: flex;
  justify-content: space-between;
}

.BoxContainer {
  position: relative;
  width: 50%;
}

.Box {
  width: 100%;
  height: 40vh;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Box.active {
  background-color: #aaa;
}

.Dropdown {
  width: 100vw;
  height: 0;
  background-color: #ddd;
  padding: 0;
  box-sizing: border-box;
  z-index: 1;
  transition: height 0.5s ease, padding 0.5s ease;
  overflow: hidden;
}

.Dropdown.active {
  height: auto;
  padding: 10px;
}

.BoxContainer:nth-child(2) .Dropdown {
  margin-left: -100%;
}

.Box.Box1 {
  background-image: url("../../../public/images/ServicesImages/MachineryMovingBG.png");
  background-size: cover;
  background-position: center;
}

.Box.Box2 {
  background-image: url("../../../public/images/ServicesImages/WarehousingBG.png");
  background-size: cover;
  background-position: center;
}

.Box.Box3 {
  background-image: url("../../../public/images/ServicesImages/TruckServicesBG.png");
  background-size: cover;
  background-position: center;
}

.Box.Box4 {
  background-image: url("../../../public/images/ServicesImages/MillwrightServicesBG.png");
  background-size: cover;
  background-position: center;
}

.Paragraph {
  font-size: 1em;
}

.Dropdown.DropdownBox1.Box.active::after {
  display: none;
}

/* DROP DOWN 1 CSS */
.Dropdown.DropdownBox1 {
  background-color: black;
  display: flex;
}

.Dropdown.DropdownBox1 .LeftHalf {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dropdown.DropdownBox1 .LeftHalf img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.Dropdown.DropdownBox1 .RightHalf {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Dropdown.DropdownBox1 .RightHalf .Title {
  text-align: center;
  font-size: 3em;
  color: white;
}

.Dropdown.DropdownBox1 .RightHalf .Paragraph {
  color: white;
  margin: 20px;
  text-align: center;
}

.Dropdown.DropdownBox1 .RightHalf .Buttons {
  display: flex;
  justify-content: space-around;
  z-index: 4;
}

/* DROP DOWN 2 CSS */
.Dropdown.DropdownBox2 {
  background-color: black;
  display: flex;
  justify-content: space-between;
}

.Dropdown.DropdownBox2 .LeftHalf {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dropdown.DropdownBox2 .LeftHalf img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.Dropdown.DropdownBox2 .RightHalf {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Dropdown.DropdownBox2 .RightHalf .Title {
  text-align: center;
  font-size: 3em;
  color: white;
}

.Dropdown.DropdownBox2 .RightHalf .Paragraph {
  color: white;
  margin: 20px;
  text-align: center;
}

.Dropdown.DropdownBox2 .RightHalf .Buttons {
  display: flex;
  justify-content: space-around;
  z-index: 4;
}

/* DROP DOWN 3 CSS */
.Dropdown.DropdownBox3 {
  background-color: black;
  display: flex;
  justify-content: space-between;
}

.Dropdown.DropdownBox3 .LeftHalf {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dropdown.DropdownBox3 .LeftHalf img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.Dropdown.DropdownBox3 .RightHalf {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Dropdown.DropdownBox3 .RightHalf .Title {
  text-align: center;
  font-size: 3em;
  color: white;
}

.Dropdown.DropdownBox3 .RightHalf .Paragraph {
  color: white;
  margin: 20px;
  text-align: center;
}

.Dropdown.DropdownBox3 .RightHalf .Buttons {
  display: flex;
  justify-content: space-around;
  z-index: 4;
}

/* DROP DOWN 4 CSS */
.Dropdown.DropdownBox4 {
  background-color: black;
  display: flex;
  justify-content: space-between;
}

.Dropdown.DropdownBox4 .LeftHalf {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dropdown.DropdownBox4 .LeftHalf img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.Dropdown.DropdownBox4 .RightHalf {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Dropdown.DropdownBox4 .RightHalf .Title {
  text-align: center;
  font-size: 3em;
  color: white;
}

.Dropdown.DropdownBox4 .RightHalf .Paragraph {
  color: white;
  margin: 20px;
  text-align: center;
}

.Dropdown.DropdownBox4 .RightHalf .Buttons {
  display: flex;
  justify-content: space-around;
  z-index: 4;
}

.ViewOurWorkButton {
  display: flex;
  justify-content: space-around;
  background-color: rgb(221, 215, 215);
  color: #000000;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(218, 213, 213, 0.5); /* optional shadow for a lifted effect */
  white-space: nowrap;
  z-index: 4;
}

.ViewOurWorkButton:hover {
  background-color: rgb(214, 55, 55);
  color: white;
}

.Dropdown.DropdownBox2 {
  background-color: black;
}

.Dropdown.DropdownBox3 {
  background-color: black;
}

.Dropdown.DropdownBox4 {
  background-color: black;
}

.BoxTitle {
  color: white;
  font-size: 3em;
  text-align: center;
}

.Triangle1 {
  padding-top: 1vh;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
  transition: transform 0.3s ease;
}

.Triangle1.active {
  transform: rotate(180deg);
  background-color: transparent;
}

@media (max-width: 600px) {
  .Dropdown.DropdownBox1 {
    flex-direction: column;
  }

  .Dropdown.DropdownBox1 .LeftHalf,
  .Dropdown.DropdownBox1 .RightHalf {
    width: 100%;
  }

  .Dropdown.DropdownBox1 .LeftHalf img {
    width: 70%;
    height: 70%;
  }

  .Dropdown.DropdownBox2 {
    flex-direction: column;
  }

  .Dropdown.DropdownBox2 .LeftHalf,
  .Dropdown.DropdownBox2 .RightHalf {
    width: 100%;
  }

  .Dropdown.DropdownBox2 .LeftHalf img {
    width: 70%;
    height: 70%;
  }

  .Dropdown.DropdownBox3 {
    flex-direction: column;
  }

  .Dropdown.DropdownBox3 .LeftHalf,
  .Dropdown.DropdownBox3 .RightHalf {
    width: 100%;
  }

  .Dropdown.DropdownBox3 .LeftHalf img {
    width: 70%;
    height: 70%;
  }

  .Dropdown.DropdownBox4 {
    flex-direction: column;
  }

  .Dropdown.DropdownBox4 .LeftHalf,
  .Dropdown.DropdownBox4 .RightHalf {
    width: 100%;
  }

  .Dropdown.DropdownBox4 .LeftHalf img {
    width: 70%;
    height: 70%;
  }


}


@media (max-width: 992px) {
  .ViewOurWorkButton {
    margin-top: 4px;
    border-radius: 25px; /* Reduced border radius for a smaller button */
    padding: 6px 15px; /* Adjusted padding for a smaller button */
    font-size: 14px; /* Reduced font size for a smaller button */
  }

  .BoxTitle {
    color: white;
    font-size: 1.4em;
    text-align: center;
  }
}