.ContactGrid {
    min-height: 100vh;
    padding-top: 10vh;
    background-image: url("/public/images/ContactImages/ContactPageBG.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: grid;
    grid-template-columns: 40% 60%;
  }
  
  .ContactInfoSection {
    grid-column: 1;
    background-color: rgba(77, 2, 2, 0.726);
    padding: 20px;
    padding-top: 10vh;
    color: white;
  }
  
  .ExpertMessage {
    margin-bottom: 10px;
  }
  
  .ContactWithTeam {
    margin-bottom: 5px;
  }
  
  .ContactDetails {
    display: flex;
    align-items: center;
  }
  
  .ContactRectangle {
    display: flex;
    align-items: center;
    background-color: rgb(214, 9, 9);
    padding: 10px;
    border-radius: 5px;
    color: white;
    margin-bottom: 10px;
  }
  
  .PhoneLogo {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .PhoneNumber {
    font-size: 14px;
  }
  
  .ContactFormSection {
    grid-column: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #cccccc91;
    margin: 50px;
    border-radius: 20px;
  }
  
  .FormTitle {
    text-align: center;
    margin-bottom: 10px;
    color: #f1ecec;
    font-weight: bold;
    
  }
  
  .FormInputs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .FormInputs input {
    position: static;
  }
  
 .FormInputs input,
.FormInputs select,
.FormInputs textarea {
  margin-bottom: 10px;
  padding: 12px;
  border: 2px solid #d1d1d1;
  border-radius: 8px;
  width: 40vw;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
}

.FormInputs input:focus,
.FormInputs select:focus,
.FormInputs textarea:focus {
  border-color: #ff6b6b; /* Change to your desired focus color */
  outline: none;
}

.FormInputs select {
  padding: 8px;
}

.FormInputs textarea {
  margin-top: 10px;
  padding: 8px;
  resize: vertical;
}

.SubmitButton {
  margin-top: 5vh;
  background-color: #ff6b6b;
  color: white;
  font-size: 20px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
}

.SubmitButton:hover {
  background-color: #ff4f4f; /* Change to your desired hover color */
}

.FormInputs .react-select-container {
  width: 40vw;
}

@media (max-width: 992px) {
  .ContactGrid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .ContactInfoSection {
    grid-column: 1;
    grid-row: 2;
    background-color: rgba(77, 2, 2, 0.726);
    padding: 20px;
    padding-top: 10vh;
    color: white;
  }

  .ContactFormSection {
    grid-column: 1;
    grid-row: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #cccccc91;
    margin-top: 70px;
    margin-bottom: 50px;
    border-radius: 20px;
  }

  .FormInputs input,
  .FormInputs select,
  .FormInputs textarea {
    width: 60vw;
  }

  .FormInputs .react-select-container {
    width: 60vw;
  }
  

  
  
}