.IconBoxContainer {
    width: 100%;
    background-image: url('/public/images/iconBoxBackground.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Keep the background fixed */
    padding-bottom: 10vh;
}

.iconBoxContent {
    padding: 6vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Create 3 equal-width columns */
}

/* Adjust the width for better responsiveness */
.calendar,
.project,
.map {
    position: absolute;
    margin-left: auto;
    margin-right: auto;

}

/* Styling for the Calendar component */
.calendarGroup {
    grid-column: 1 / span 1;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

/* Styling for the Project component */
.projectGroup {
    grid-column: 2 / span 1;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

/* Styling for the Map component */
.mapGroup {
    grid-column: 3 / span 1;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.IconText {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    position: absolute;
}

@media (max-width: 992px) {
    .IconBoxContainer {
        width: 100%;
    }

    .iconBoxContent {
        grid-template-columns: repeat(1, 1fr); /* Change to 1 column layout */
        grid-template-rows: repeat(3, 1fr); /* Create 3 equal-height rows */
        gap: 100px; /* Add some gap between the boxes */
    }
    
    /* Adjust grid column spans for vertical layout */
    .calendarGroup,
    .projectGroup,
    .mapGroup {
        grid-column: 1 / span 1; /* Each item occupies one row */
    }
}
