.AUHeroContainer {
    min-height: 45vh;
    background-image: url("/public/images/AUImages/HeroImage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    text-align: left;
    overflow: hidden;
}

.AUTitleContainer {
    padding-top: 25vh;
    padding-left: 10vw;
}

.AUHeroTitle {
    font-size: 100px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 106px;
    text-transform: none;
    text-decoration: solid underline #ff0000;
    color: #ffffff;
}

.AUHeroText {
    position: relative;
    color: #ffffff;
    font-size: 30px;
}

@media (max-width: 992px) {
    .AUHeroTitle {
        font-size: 100px;
    }
    .AUHeroText {
        font-size: 30px;
    }
}
