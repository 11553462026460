.GalleryContainer {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

.GNavbar-brand {
  font-size: 1.5rem;
}

/* Navigation styles */
.GNav {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 10px;
  
}

.GNav-item {
  margin: 0 10px;
}

.GNav-link {
  text-decoration: none;
  color: rgb(255, 255, 255); /* Set the text color to black */
  font-weight: bold;
  padding: 5px 10px;
  border: none; /* Remove the border */
  background-color: transparent; /* Remove the background color */
  border-radius: 0; /* Remove border radius */
}

.GNav-link:hover {
  color: rgb(194, 90, 90); /* or any color that is visible on your background */
}

.GNav-link.Active {
    background-color: transparent;
    color: rgb(241, 61, 61); /* or any color that is visible on your background */
  }

/* Card styles */
.GContainer-fluid {
  padding: 0 20px;
}

.GRow {
  display: flex;
  flex-wrap: wrap;
}

.GCol-sm-4 {
  width: calc(33.33% - 20px);
  margin: 10px;
}

.GCard {
  border: 1px solid gray;
  border-radius: 5px;
}

.GCard-img {
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.GCard-img img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

.GCard-body {
  padding: 10px;
}

.GCard-title {
  font-size: 1.25rem;
  margin-bottom: 5px;
}

.GCard-text {
  font-size: 0.9rem;
  color: gray;
  margin-bottom: 10px;
}

.Gd-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Gp-1 {
  padding: 5px;
}

.GBtn {
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

/* Add this to your existing CSS */
.ImageModal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  
  .ImageModal img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  
  .CloseIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  

/* Media Query for responsive layout */
@media (max-width: 992px) {
  .GRow {
    flex-direction: column;
  }

  .GCol-sm-4 {
    width: 100%;
    margin: 10px 0; /* Adjust the margin to create spacing between cards */
  }
  
  .GNav {
    flex-direction: column; /* Stack navigation links vertically */
    align-items: center; /* Center align navigation links */
  }
  
  .GNav-item {
    margin: 5px 0; /* Adjust margin for navigation items */
  }
}
